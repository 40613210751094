const styles = {
  wrapper: {
    borderBottom: "1px solid #6197ce",
    height: 0,
    margin: "32px 0",
  },
};

function Separator() {
  return <div style={styles.wrapper} />;
}

export default Separator;
