import { debounce } from "lodash";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row, Toast } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { MostKilledList } from "../components/player/MostKilledList";
import PlayerNumberCard from "../components/player/PlayerNumberCard";
import PlayerTitle from "../components/player/PlayerTitle";
import moment from "moment";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { PlayerLineStat } from "../components/player/PlayerLineStat";
import PlayerPKSpreadChart from "../components/charts/PlayerPKSpreadChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const API_HOST = process.env.REACT_APP_API;

const styles = {
  title: {
    color: "rgb(70, 130, 180)",
    marginBottom: "16px",
    marginTop: "32px",
  },
};

const getPrettifiedRankValue = (value) => {
  if (value) {
    return `#${value}`;
  }
  return "NA";
};

const last6MonthsOptions = {
  responsive: true,
  scales: {
    x: {
      ticks: { color: "rgb(199, 238, 255)" },
      grid: {
        color: "rgb(70, 130, 180)",
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        color: "rgb(199, 238, 255)",
        position: "right",
      },
      grid: {
        color: "rgb(70, 130, 180)",
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
      labels: {
        color: "rgb(199, 238, 255)",
      },
    },
    title: {
      display: false,
      text: "Last 6 months",
      color: "white",
    },
  },
};

const last12MonthsOptions = {
  responsive: true,
  scales: {
    x: {
      ticks: { color: "rgb(199, 238, 255)" },
      grid: {
        color: "rgb(70, 130, 180)",
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        color: "rgb(199, 238, 255)",
        position: "right",
      },
      grid: {
        color: "rgb(70, 130, 180)",
      },
    },
  },
  plugins: {
    legend: {
      position: "top",
      labels: {
        color: "rgb(199, 238, 255)",
      },
    },
    title: {
      display: false,
      text: "Last 6 months",
      color: "white",
    },
  },
};

const last12MonthsLabels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
  .map((e) => moment().subtract(e, "months").format("MMM"))
  .reverse();

const getLast12MonthsData = (data) => {
  return {
    labels: last12MonthsLabels,
    datasets: [
      {
        label: "PKs last 12 months",
        data: last12MonthsLabels.map((e) => {
          const foundDataForMonth = data.find(
            (s) => s.month === Number(moment(e, "MMM").format("M"))
          );
          if (foundDataForMonth) {
            return foundDataForMonth.pks;
          }
          return 0;
        }),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
};

const last6MonthsLabels = [0, 1, 2, 3, 4, 5]
  .map((e) => moment().subtract(e, "months").format("MMM"))
  .reverse();

const getLast6MonthsData = (data) => {
  return {
    labels: last6MonthsLabels,
    datasets: [
      {
        label: "PKs last 6 months",
        data: last6MonthsLabels.map((e) => {
          const foundDataForMonth = data.find(
            (s) => s.month === Number(moment(e, "MMM").format("M"))
          );
          if (foundDataForMonth) {
            return foundDataForMonth.pks;
          }
          return 0;
        }),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
};

const useDebouncedCallback = (callback, delay, opts) => {
  const callbackRef = useRef();
  callbackRef.current = callback;
  return debounce((...args) => callbackRef.current(...args), delay, opts);
};

export const Player = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [options, setOptions] = useState([]);
  const [str, setStr] = useState(searchParams.get("player") || "");
  const [realPlayerName, setRealPlayerName] = useState(
    searchParams.get("player")
  );
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [playerData, setPlayerData] = useState();
  const [showNotFound, setShowNotFound] = useState(false);

  const debouncedOnSearch = useDebouncedCallback(
    async (value) => {
      const data = await (
        await fetch(
          `${API_HOST}public/player-name-suggestions?partialName=${value}`
        )
      ).json();
      setOptions(data);
      setShowSuggestions(true);
    },
    500,
    { leading: true, trailing: true }
  );

  const handleInputChange = async ({ target: { value } }) => {
    setStr(value);
    if (value?.length > 2) {
      debouncedOnSearch(value);
    }
  };

  const handleSuggestionClick = (name) => {
    setRealPlayerName(name);
    setSearchParams({ player: name });
    setShowSuggestions(false);
  };

  useEffect(() => {
    const fetchPlayerData = async () => {
      const data = await (
        await fetch(`${API_HOST}public/player/${realPlayerName}`)
      ).json();
      setPlayerData(data);
      const notFound = !data || (data.kills === null && data.deaths === null);
      setShowNotFound(notFound);
    };
    if (realPlayerName) {
      // Send event
      if (process.env.NODE_ENV !== "development") {
        window.gtag("event", "player_search", {
          name: realPlayerName,
        });
      }
      fetchPlayerData();
    }
  }, [realPlayerName]);

  return (
    <>
      <Container>
        <Row>
          <PlayerTitle playerName={realPlayerName} />
        </Row>
        <Row>
          <Col>
            <Form.Label htmlFor="playername" style={{ color: "#98d8ff" }}>
              Player name
            </Form.Label>
            <Form.Control
              id="playername"
              aria-describedby="player name search"
              onChange={handleInputChange}
              value={str}
              className="w-100"
            />
            <Form.Text id="playername help" style={{ color: "#f1f4c6" }}>
              3 characters minimum
            </Form.Text>
            {showSuggestions && (
              <div className="mt-3">
                {options?.map((e, i) => {
                  return (
                    <Button
                      variant="primary"
                      key={i}
                      onClick={() => handleSuggestionClick(e)}
                      className="me-3"
                    >
                      {e}
                    </Button>
                  );
                })}
              </div>
            )}

            {showNotFound && (
              <Toast show={true} animation={true} className="mt-5">
                <Toast.Body>Not found!</Toast.Body>
              </Toast>
            )}
          </Col>
        </Row>
        {playerData?.killsLast12Months && (
          <Row className="mt-5">
            <Col md={6}>
              <Line
                options={last6MonthsOptions}
                data={getLast6MonthsData(playerData.killsLast12Months)}
              />
            </Col>
            <Col
              md={6}
              className="mt-5 mt-md-0 d-flex flex-column justify-content-center align-items-end"
            >
              <PlayerLineStat
                name="Rank"
                value={getPrettifiedRankValue(
                  playerData.ranks?.find((e) => e.type === "points")?.row_num ||
                    "NA"
                )}
              />
              <PlayerLineStat
                name="PK Rank"
                value={getPrettifiedRankValue(
                  playerData.ranks?.find((e) => e.type === "pk")?.row_num ||
                    "NA"
                )}
              />
              <PlayerLineStat
                name="HC Rank"
                value={getPrettifiedRankValue(
                  playerData.ranks?.find((e) => e.type === "hc")?.row_num ||
                    "NA"
                )}
              />
              <PlayerLineStat
                name="Assist Rank"
                value={getPrettifiedRankValue(
                  playerData.ranks?.find((e) => e.type === "assists")
                    ?.row_num || "NA"
                )}
              />
              <PlayerLineStat
                name="Streak"
                value={playerData.streak}
                type="streak"
              />
            </Col>
          </Row>
        )}

        {playerData && playerData.kills !== null && (
          <Row className="mt-5 d-flex justify-content-center">
            <Col md={6}>
              <PlayerPKSpreadChart
                data={[playerData.kills.pk, playerData.kills.hc]}
              />
            </Col>
          </Row>
        )}

        {playerData && playerData.kills !== null && (
          <Row>
            <h3 style={styles.title}>KILLS</h3>
            <Col md={3} xs={6}>
              <PlayerNumberCard
                title="total"
                number={playerData?.kills?.total}
              />
            </Col>
            <Col md={3} xs={6}>
              <PlayerNumberCard title="opk" number={playerData?.kills?.pk} />
            </Col>
            <Col md={3} xs={6}>
              <PlayerNumberCard title="hc" number={playerData?.kills?.hc} />
            </Col>
            <Col md={3} xs={6}>
              <PlayerNumberCard
                title="wanted"
                number={playerData?.kills?.wanted}
              />
            </Col>
          </Row>
        )}

        {playerData && playerData.deaths !== null && (
          <Row>
            <h3 style={styles.title}>DEATHS</h3>
            <Col md={3} xs={6}>
              <PlayerNumberCard
                title="total"
                number={playerData?.deaths?.total}
              />
            </Col>
            <Col md={3} xs={6}>
              <PlayerNumberCard title="opk" number={playerData?.deaths?.pk} />
            </Col>
            <Col md={3} xs={6}>
              <PlayerNumberCard title="hc" number={playerData?.deaths?.hc} />
            </Col>
            <Col md={3} xs={6}>
              <PlayerNumberCard
                title="wanted"
                number={playerData?.deaths?.wanted}
              />
            </Col>
          </Row>
        )}

        {playerData?.killsLast12Months && (
          <Row className="mt-4">
            <Col md={12}>
              <Line
                options={last12MonthsOptions}
                data={getLast12MonthsData(playerData.killsLast12Months)}
              />
            </Col>
          </Row>
        )}

        {/* Most killed and Most killed by */}
        {realPlayerName && (
          <Row className="mb-5">
            {playerData && playerData.mostKilled?.length > 0 && (
              <Col md={6} xs={12}>
                <h3 style={styles.title}>TOP 10 MOST KILLED</h3>
                <MostKilledList
                  data={
                    playerData &&
                    playerData.mostKilled?.map((e) => {
                      return { key: e.target, value: e.count };
                    })
                  }
                />
              </Col>
            )}
            {playerData && playerData.mostKilledBy?.length > 0 && (
              <Col md={6} xs={12}>
                <h3 style={styles.title}>TOP 10 MOST KILLED BY</h3>
                <MostKilledList
                  data={
                    playerData &&
                    playerData.mostKilledBy?.map((e) => {
                      return { key: e.by, value: e.count };
                    })
                  }
                />
              </Col>
            )}
          </Row>
        )}
      </Container>
    </>
  );
};
