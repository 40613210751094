export const getShortClanNameFromLong = (longName) => {
  return clanNames[longName] || "NA";
};

const clanNames = {
  "Boot Camp": "boot",
  "Clan Imperium": "imperium",
  "Crusaders of the Nameless One": "crusader",
  "Dark Templars of Rhabdo Rana": "rhabdo",
  "House of Touchstone": "touchstone",
  "Knights of Perdition": "perdition",
  "Masaki Clan": "masaki",
  "Order of the Bard": "bard",
  "The Children of Baalzamon": "baal",
  "The Children of Ba'alzamon": "baal",
  "The Creation of Tao": "tao",
  "The Crimson Horde": "crimson",
  "The Emerald Knights": "emerald",
  "The Fellowship of the Twin Lobes": "twinlobe",
  "The Great Circle of Druids": "druids",
  "The Hook Clan": "hook",
  "The Lone Adventurers": "loner",
  "The Midgaardian Publishing Group": "gaardian",
  "The Order of Light": "light",
  "The Outcast Clan of Shame": "outcast",
  "The Seekers": "seekers",
  "The Soul Pyre": "pyre",
  "The Tribes of the Amazon": "amazon",
  "The Watchmen of Aardwolf": "watchmen",
  "The Wolf Administration": "wolf",
  "The Xunti Cult": "xunti",
  Loqui: "loqui",
  Retribution: "retribution",
  unclanned: "unclanned",
};
