import { Line } from "react-chartjs-2";

const subclassColorMap = {
  Shaman: "rgb(0, 112, 222)", // Shaman - Shamanistic blue
  Hunter: "rgb(171, 212, 115)", // Hunter - Nature green
  Crafter: "rgb(238, 187, 68)", // Crafter - Artisan yellow
  Elementalist: "rgb(255, 121, 0)", // Elementalist - Elemental orange
  Enchanter: "rgb(163, 53, 238)", // Enchanter - Arcane purple
  Sorcerer: "rgb(135, 4, 132)", // Sorcerer - Shadowy purple
  Guardian: "rgb(197, 199, 201)", // Guardian - Stoic gray
  Knight: "rgb(245, 140, 186)", // Knight - Holy pink
  Avenger: "rgb(220, 20, 60)", // Avenger - Retribution red
  Priest: "rgb(255, 255, 255)", // Priest - Divine white
  Oracle: "rgb(255, 128, 0)", // Oracle - Prophetic orange
  Harmer: "rgb(255, 245, 105)", // Harmer - Bright gold
  Ninja: "rgb(0, 0, 0)", // Ninja - Stealthy black
  Bandit: "rgb(105, 105, 105)", // Bandit - Shadowy gray
  Venomist: "rgb(0, 255, 0)", // Venomist - Toxic green
  Mentalist: "rgb(105, 180, 210)", // Mentalist - Mystic blue
  Navigator: "rgb(25, 25, 112)", // Navigator - Nautical blue
  Necromancer: "rgb(107, 66, 66)", // Necromancer - Deathly brown
  Barbarian: "rgb(205, 133, 63)", // Barbarian - Savage orange
  Soldier: "rgb(69, 69, 69)", // Soldier - Dark gray
  Blacksmith: "rgb(169, 169, 169)", // Blacksmith - Forged steel
};

const SubclassesChart = ({ data }) => {
  // Extracting days and subclasses from the data
  const days = data.map((entry) => entry.day);

  // Prepare datasets for Chart.js
  const datasets = Object.keys(subclassColorMap).map((subclass) => {
    const numbers = data.map((entry) => {
      const subclassData = entry.subclasses.find(
        (obj) => Object.keys(obj)[0] === subclass
      );
      return subclassData ? subclassData[subclass].number : 0; // If subclass doesn't exist for a day, set number to 0
    });
    return {
      label: subclass,
      data: numbers,
      fill: false,
      borderColor: subclassColorMap[subclass] || getRandomColor(), // Assign color based on subclassColorMap or generate random color
    };
  });

  const chartData = {
    labels: days,
    datasets: datasets,
  };

  const options = {
    indexAxis: "x",
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        ticks: { color: "rgb(199, 238, 255)" },
      },
      y: {
        afterFit: function (scaleInstance) {
          scaleInstance.width = 75;
        },
        ticks: {
          color: "rgb(199, 238, 255)",
          position: "right",
          minWidth: "300px",
        },
        grid: {
          color: "rgb(70, 130, 180)",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: "rgb(199, 238, 255)",
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

// Function to generate random color
function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default SubclassesChart;
