import { Line } from "react-chartjs-2";

const raceColorMap = {
  Human: "rgb(165, 42, 42)",
  Elf: "rgb(0, 128, 0)",
  Dwarf: "rgb(128, 128, 128)",
  Giant: "rgb(0, 0, 255)",
  Centaur: "rgb(165, 42, 42)",
  "Half-griffon": "rgb(218, 165, 32)",
  Sprite: "rgb(255, 255, 0)",
  "Dark elf": "rgb(0, 0, 0)",
  Halfling: "rgb(210, 180, 140)",
  Quickling: "rgb(192, 192, 192)",
  Troll: "rgb(0, 128, 0)",
  Vampire: "rgb(255, 240, 245)",
  Wolfen: "rgb(128, 128, 128)",
  Ratling: "rgb(139, 69, 19)",
  Diva: "rgb(238, 130, 238)",
  Shadow: "rgb(0, 0, 0)",
  Triton: "rgb(0, 0, 255)",
  Lizardman: "rgb(0, 128, 0)",
  Eldar: "rgb(192, 192, 192)",
};

const RacesChart = ({ data }) => {
  const days = data.map((entry) => entry.day);

  // Prepare datasets for Chart.js
  const datasets = Object.keys(raceColorMap).map((race) => {
    const numbers = data.map((entry) => {
      const raceData = entry.races.find((obj) => Object.keys(obj)[0] === race);
      return raceData ? raceData[race].number : 0;
    });
    return {
      label: race,
      data: numbers,
      fill: false,
      borderColor: raceColorMap[race] || getRandomColor(),
    };
  });

  const chartData = {
    labels: days,
    datasets: datasets,
  };

  const options = {
    indexAxis: "x",
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        ticks: { color: "rgb(199, 238, 255)" },
      },
      y: {
        afterFit: function (scaleInstance) {
          scaleInstance.width = 75;
        },
        ticks: {
          color: "rgb(199, 238, 255)",
          position: "right",
          minWidth: "300px",
        },
        grid: {
          color: "rgb(70, 130, 180)",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: "rgb(199, 238, 255)",
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

// Function to generate random color
function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export default RacesChart;
