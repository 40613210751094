import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { getShortClanNameFromLong } from "../../utils/getShortClanNameFromLong";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    x: {
      ticks: { color: "rgb(199, 238, 255)" },
      grid: {
        color: "rgb(70, 130, 180)",
      },
    },
    y: {
      afterFit: function (scaleInstance) {
        scaleInstance.width = 75;
      },
      ticks: {
        color: "rgb(199, 238, 255)",
        position: "right",
        minWidth: "300px",
      },
      grid: {
        color: "rgb(70, 130, 180)",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

function getName(type, value) {
  if (type === "clan") {
    let res = getShortClanNameFromLong(value);
    let firstChar = res.charAt(0).toUpperCase();
    res = firstChar + res.slice(1);
    return res;
  }
  return value;
}

export default function Top5KillsChart(props) {
  const { barColor = "#393e6f", data, type } = props;

  return (
    <Bar
      options={options}
      data={{
        labels: data.map((e) => getName(type, e.name)),
        datasets: [
          {
            data: data.map((e) => e.pks),
            borderColor: "#796dce",
            backgroundColor: barColor,
          },
        ],
      }}
    />
  );
}
