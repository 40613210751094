import moment from "moment";

const epicMinutes = {
  fractals: 15,
  genie: 45,
  if1: 45,
  if2: 45,
  inferno: 45,
  oc: 15,
  transcendence: 45,
  winds: 15,
};

/**
 * Return true if epic is over
 * @param {comma separated epics} epics
 * @param {timestamp for epic} timestamp
 * @returns
 */
export const isEpicDone = (epics, timestamp) => {
  if (epics && timestamp) {
    let _offset = 0;

    epics.split(", ").forEach((epic) => {
      _offset += epicMinutes[epic.toLowerCase()] || 0;
    });

    if (moment(timestamp).add(_offset, "minutes") < moment()) {
      return true;
    }

    return false;
  }
};
