import { Outlet } from "react-router-dom";

import { usePageTracking } from "../hooks/usePageTracking";
import Header from "./Header";

function Layout() {
  usePageTracking();
  return (
    <div className="Layout">
      <Header />
      <Outlet />
      {/* <div>footer</div> */}
    </div>
  );
}

export default Layout;
