import { createBrowserRouter } from "react-router-dom";

import Changelog from "../screens/Changelog";
import Feed from "../screens/Feed";
import Home from "../screens/Home";
import LastWeek from "../screens/LastWeek";
import Layout from "../layout/Layout";
import Leaderboards from "../screens/Leaderboards";
import MarchEvent from "../screens/MarchEvent";
import RaceModifiers from "../screens/RaceModifiers";
import Races from "../screens/Races";
import Subclasses from "../screens/Subclasses";
import Test from "../screens/Test";
import { EpicsRoute } from "../screens/EpicsRoute";
import { Player } from "../screens/Player";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      { index: true, element: <Home /> },
      { path: "/leaderboards", element: <Leaderboards /> },
      { path: "/last", element: <LastWeek /> },
      { path: "/feed", element: <Feed /> },
      { path: "/changelog", element: <Changelog /> },
      { path: "/player", element: <Player /> },
      { path: "/light-birthday", element: <MarchEvent /> },
      { path: "/epics", element: <EpicsRoute /> },
      { path: "/subclasses", element: <Subclasses /> },
      { path: "/races", element: <Races /> },
      { path: "/race-modifiers", element: <RaceModifiers /> },
      { path: "/test", element: <Test /> },
      //   {
      //     path: "/courses",
      //     element: <Courses />,
      //     children: [
      //       { index: true, element: <CoursesIndex /> },
      //       { path: "/courses/:id", element: <Course /> },
      //     ],
      //   },
      //   { path: "*", element: <NoMatch /> },
    ],
  },
]);
