import { Container, Row } from "react-bootstrap";
import { Title } from "../components/common/Title";
import { Epic } from "../types/Epic";
import moment from "moment";
import { useEffect, useState } from "react";
import { SchedulerEvent } from "../types/SchedulerEvent";
import { Calendar, momentLocalizer } from "react-big-calendar";

import "react-big-calendar/lib/css/react-big-calendar.css";

import "./styles.css";

const localizer = momentLocalizer(moment);

const epicMinutes: { [key: string]: number } = {
  fractals: 15,
  genie: 30,
  if1: 30,
  if2: 30,
  inferno: 30,
  oc: 15,
  transcendence: 30,
  winds: 15,
};

const parseEpics = (epics: Epic[]) => {
  if (epics?.length > 0) {
    const res: SchedulerEvent[] = [];
    epics.forEach((epic: Epic) => {
      let _startTime = moment(epic.startTimestamp);
      const _splitEpics = epic.epics.split(", ");
      _splitEpics.forEach((e: string) => {
        res.push({
          end: _startTime
            .clone()
            .add(epicMinutes[e.toLowerCase()] || 0, "minutes")
            .toDate(),
          event_id: epic.id + "_" + e,
          start: _startTime.toDate(),
          title: e,
        });
        // increase start time
        _startTime = _startTime.add(
          epicMinutes[e.toLowerCase()] || 0,
          "minutes"
        );
      });
    });
    return res;
  }

  return [];
};

export const EpicsRoute = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // const API_HOST = process.env.REACT_APP_API;
    const API_HOST = "https://lightclan.net:3000/api/";

    // fetch data
    const dataFetch = async () => {
      const data = await (await fetch(`${API_HOST}public/epics`)).json();

      // set state when the data received
      setData(data);
      setLoading(false);
    };

    dataFetch();

    // Re-fetch timer
    const timeToFetch = 5 * 60 * 1000;
    const interval = setInterval(() => {
      dataFetch();
    }, timeToFetch);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Row>
        <Title text="Epics Schedule (your local time)" />
      </Row>
      <Container>
        <Row>
          {loading && (
            <div className="spinner-border text-warning" role="status">
              <span className="sr-only" />
            </div>
          )}
          {!loading && (
            <Calendar
              localizer={localizer}
              events={parseEpics(data)}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 1250 }}
              defaultView="week"
              showMultiDayTimes={true}
              formats={{
                eventTimeRangeFormat: () => {
                  return "";
                },
              }}
            />
          )}
        </Row>
      </Container>
    </Container>
  );
};
