import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import FeedTable from "../components/feed/FeedTable";

function Feed() {
  const [searchParams] = useSearchParams();

  const [search, setSearch] = useState(searchParams?.get("search") || "");
  const [week, setWeek] = useState("");
  const [filterByPKer, setFilterByPKer] = useState(
    searchParams?.get("pker") || ""
  );

  return (
    <Container>
      <Row className="mt-5">
        <Col md={3} className="mb-3">
          <Form.Label style={{ color: "#6197ce", fontWeight: "bold" }}>
            Week
          </Form.Label>
          <Form.Select
            aria-label="Pick a week"
            onChange={(e) => setWeek(e.target.value)}
          >
            <option value="this">This week</option>
            <option value="last">Last week</option>
          </Form.Select>
        </Col>
        <Col md={3} className="mb-3">
          <Form.Label
            style={{ color: "#6197ce", fontWeight: "bold" }}
            className="text-bold"
          >
            Search
          </Form.Label>
          <Form.Control
            aria-label="Search"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </Col>
        <Col md={3} className="mb-3">
          <Form.Label
            style={{ color: "#6197ce", fontWeight: "bold" }}
            className="text-bold"
          >
            Filter by PKer
          </Form.Label>
          <Form.Control
            aria-label="Filter by PKer"
            onChange={(e) => setFilterByPKer(e.target.value)}
            value={filterByPKer}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FeedTable week={week} search={search} pker={filterByPKer} />
        </Col>
      </Row>
    </Container>
  );
}

export default Feed;
