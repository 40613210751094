import { useEffect, useState } from "react";
import { Col, ListGroup } from "react-bootstrap";

const getListItemStyles = (mode, type) => {
  switch (type) {
    case "hc":
      return {
        backgroundColor: "#2c3f52",
        color: "rgb(199, 238, 255)",
      };
    case "opk":
      return {
        backgroundColor: "rgb(33, 37, 41)",
        color: "rgb(199, 238, 255)",
      };
    default:
      return {
        backgroundColor: "#363b4e",
        color: "rgb(199, 238, 255)",
      };
  }
};

const getListItemText = (date, name, mode, type) => {
  if (type === "opk") {
    if (mode === "on") {
      return `[${date}] ${name} has entered open PK (OPK) mode!`;
    }
    return `[${date}] ${name} has removed themselves from the (OPK) victim pool!`;
  }
  return `[${date}] ${name} has entered (HARDCORE) mode!`;
};

export const RecentActivity = () => {
  const [opkData, setOpkData] = useState([]);
  const [hcData, setHcData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const API_HOST = process.env.REACT_APP_API;

    // fetch data
    const dataFetch = async () => {
      const data = await (
        await fetch(`${API_HOST}public/stats/recent-opkhc`)
      ).json();

      // set state when the data received
      setOpkData(data.filter((e) => e.type === "opk"));
      setHcData(data.filter((e) => e.type === "hc"));

      setLoading(false);
    };

    dataFetch();

    // Re-fetch timer
    const timeToFetch = 10 * 60 * 1000;
    const interval = setInterval(() => {
      dataFetch();
    }, timeToFetch);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <Col md={6}>
        <ListGroup className="mb-4 mb-md-0">
          {(loading && (
            <div className="spinner-border text-warning" role="status">
              <span className="sr-only" />
            </div>
          )) ||
            hcData.map((e) => {
              return (
                <ListGroup.Item
                  style={getListItemStyles(e.mode, e.type)}
                  key={e.id}
                  className="text-truncate"
                >
                  {getListItemText(e.dateString, e.name, e.mode, e.type)}
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Col>
      <Col md={6}>
        <ListGroup className="mb-4 mb-md-0">
          {(loading && (
            <div className="spinner-border text-warning" role="status">
              <span className="sr-only" />
            </div>
          )) ||
            opkData.map((e) => {
              return (
                <ListGroup.Item
                  style={getListItemStyles(e.mode, e.type)}
                  key={e.id}
                  className="text-truncate"
                >
                  {getListItemText(e.dateString, e.name, e.mode, e.type)}
                </ListGroup.Item>
              );
            })}
        </ListGroup>
      </Col>
    </>
  );
};
