import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

import Table from "../common/Table";
import "./styles.css";

const styles = {
  wrapper: {
    marginTop: "32px",
  },
};

const dataColors = [
  "#ebd9dd",
  "#c7eeff",
  "#ffa87b",
  "#bddaa5",
  "#e04b5a",
  "#d580bc",
  "#d6d0b8",
  "#60a0b0",
  "#c7eeff",
  "#bddaa5",
  "#e04b5a",
];

const getColumnNames = (type, clanOrPlayer) => {
  const name = clanOrPlayer === "clan" ? "Clan" : "Player";
  switch (type) {
    case "all":
      return [
        "#",
        name,
        "KDA",
        "Total kills",
        "PK kills",
        "HC kills",
        "Wanted kills",
        "Total deaths",
        "PK deaths",
        "HC deaths",
        "Wanted deaths",
        "Assists",
      ];
    case "hc":
      return ["#", name, "KDA", "HC kills", "HC deaths", "Assists"];
    case "pk":
      return ["#", name, "KDA", "PK kills", "PK deaths", "Assists"];
    default:
      return [
        "#",
        name,
        "KDA",
        "Total kills",
        "PK kills",
        "HC kills",
        "Wanted kills",
        "Total deaths",
        "PK deaths",
        "HC deaths",
        "Wanted deaths",
        "Assists",
      ];
  }
};

const tableRowData = (type, item, index) => {
  switch (type) {
    case "all":
      return [
        index + 1,
        item.name,
        item.kda,
        item.totalKills,
        item.pkKills,
        item.hcKills,
        item.wantedKills,
        item.totalDeaths,
        item.pkDeaths,
        item.hcDeaths,
        item.wantedDeaths,
        item.assists,
      ];
    case "hc":
      return [
        index + 1,
        item.name,
        item.kda,
        item.hcKills,
        item.hcDeaths,
        item.assists,
      ];
    case "pk":
      return [
        index + 1,
        item.name,
        item.kda,
        item.pkKills,
        item.pkDeaths,
        item.assists,
      ];
    default:
      return [
        index + 1,
        item.name,
        item.kda,
        item.totalKills,
        item.pkKills,
        item.hcKills,
        item.wantedKills,
        item.totalDeaths,
        item.pkDeaths,
        item.hcDeaths,
        item.wantedDeaths,
        item.assists,
      ];
  }
};

const getURLQuery = (month, week, sort, type) => {
  let queryString = "?";
  let params = [];
  if (week && week !== "all") {
    params.push(`week=${week}`);
  } else if (month) {
    params.push(`month=${month}`);
  }
  if (sort) {
    params.push(`sort=${sort}`);
  }
  if (type) {
    params.push(`type=${type}`);
  }
  params.forEach((param, index) => {
    queryString += `${index === 0 ? "" : "&"}${param}`;
  });
  return queryString;
};

const searchFilter = (data, filter) => {
  if (filter) {
    return data.filter((row) => {
      const foundInName =
        row[1].toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      return foundInName;
    });
  } else {
    return data;
  }
};

function LeaderboardsTable(props) {
  const { search, week, month, sort, type } = props;

  const [tableDataPlayers, setTableDataPlayers] = useState([]);
  const [tableDataClans, setTableDataClans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tooltipsPlayers, setTooltipsPlayers] = useState([]);

  useEffect(() => {
    const API_HOST = process.env.REACT_APP_API;

    // fetch data
    const dataFetch = async () => {
      setLoading(false);
      const data = await (
        await fetch(
          `${API_HOST}public/leaderboards${getURLQuery(
            month,
            week,
            sort,
            type
          )}`
        )
      ).json();
      if (data) {
        let playerData = [];
        let clanData = [];
        let playerTooltips = [];
        data.player?.forEach((item, index) => {
          playerData.push(tableRowData(type, item, index));
          playerTooltips.push(["", item.clan, "", "", "", "", "", ""]);
        });
        data.clan?.forEach((item, index) => {
          clanData.push(tableRowData(type, item, index));
        });
        setTooltipsPlayers(playerTooltips);
        setTableDataPlayers(playerData);
        setTableDataClans(clanData);
        setLoading(false);
      }
    };

    dataFetch();
  }, [week, month, sort, type]);

  return (
    <div style={styles.wrapper}>
      {(loading && (
        <div className="spinner-border text-warning" role="status">
          <span className="sr-only" />
        </div>
      )) || (
        <Tabs
          defaultActiveKey="players"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab
            eventKey="players"
            title="Players"
            tabClassName="leaderboards-tabitem"
          >
            <Table
              columns={getColumnNames(type, "player")}
              data={searchFilter(tableDataPlayers, search) || []}
              filterStrings={[search]}
              dataColors={dataColors}
              tooltips={tooltipsPlayers}
            />
          </Tab>
          <Tab
            eventKey="clans"
            title="Clans"
            tabClassName="leaderboards-tabitem"
          >
            <Table
              columns={getColumnNames(type, "clan")}
              data={searchFilter(tableDataClans, search) || []}
              filterStrings={[search]}
              dataColors={dataColors}
              sort={sort}
            />
          </Tab>
        </Tabs>
      )}
    </div>
  );
}

export default LeaderboardsTable;
