import moment from "moment";
import { useEffect, useState } from "react";
import Table from "../common/Table";

const styles = {
  wrapper: {},
};

const columns = ["#", "Date", "PKer", "PKee", "Assists"];

function LastWeekFeed() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const API_HOST = process.env.REACT_APP_API;
    // fetch data
    const dataFetch = async () => {
      const data = await (
        await fetch(`${API_HOST}public/get-weekly-pks?lastWeek=true`)
      ).json();
      if (data) {
        let res = [];
        data.forEach((item, index) => {
          res.push([
            index + 1,
            moment(item.date, "YYYY-MM-DDTHH:mm:ss").format("MMM DD HH:mm:ss"),
            item.killer,
            item.killed,
            item.assists.map((assist) => assist.assistKiller),
          ]);
        });
        setTableData(res);
        setLoading(false);
      }
    };

    dataFetch();
  }, []);
  return (
    <div style={styles.wrapper} className="row">
      {(loading && (
        <div className="spinner-border text-warning" role="status">
          <span className="sr-only" />
        </div>
      )) || <Table columns={columns} data={tableData || []} />}
    </div>
  );
}

export default LastWeekFeed;
