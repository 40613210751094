const styles = {
  title: {
    color: "rgb(228, 229, 242)",
    fontSize: "40px",
    textTransform: "uppercase",
    letterSpacing: "10px",
  },
  subtitle: {
    color: "rgb(70, 130, 180)",
    textTransform: "uppercase",
  },
  highlight: {
    color: "#aed9e0",
  },
  button: {
    borderColor: "black",
    color: "rgb(70, 130, 180)",
  },
  buttonSelected: {
    borderColor: "black",
    color: "rgb(174, 217, 224)",
  },
};

function PlayerTitle(props) {
  const { playerName } = props;

  return (
    <div className="d-flex flex-column align-items-start justify-content-center mt-4 mb-4">
      <h3
        style={styles.subtitle}
        className="d-flex justify-content-between w-100 m-0"
      >
        <span className="d-flex flex-column flex-md-row">
          <span className="me-3">Player:</span>{" "}
          <span style={styles.highlight}>{playerName}</span>
        </span>
      </h3>
    </div>
  );
}

export default PlayerTitle;
