import moment from "moment";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useIsMobile from "../../hooks/useIsMobile";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { getShortClanNameFromLong } from "../../utils/getShortClanNameFromLong";
import Table2 from "../common/Table2";
import { Streak } from "../common/Streak";
import { NavLink } from "react-router-dom";
import { convertNYTimeToUTC } from "../../utils/convertNYTimeToUTC";

const styles = {
  wrapper: {},
  tooltipValue: {
    color: "rgb(174, 217, 224)",
  },
};

/**
 * Gets tier and returns base tier without redoes
 * @param {string} tier
 * @returns
 */
const parseTier = (tier) => {
  if (tier) {
    const isRedoer = tier.search("\\+");
    if (isRedoer !== -1) {
      return tier.slice(0, isRedoer);
    }
    return tier;
  }
  return "";
};

const getDateFormat = (date, isMobile) => {
  if (!isMobile) {
    // if (moment(date, "YYYY-MM-DDTHH:mm:ss").isSame(new Date(), "day")) {
    //   return "Today " + moment(date, "YYYY-MM-DDTHH:mm:ss").format("h:mm A");
    // }
    // return moment(date, "YYYY-MM-DDTHH:mm:ss").format("ddd h:mm A");
    return capitalizeFirstLetter(convertNYTimeToUTC(date).fromNow());
  }
  return moment(date, "YYYY-MM-DDTHH:mm:ss").format("ddd h:mm A");
};

const desktopColumns = [
  "Date",
  "Level",
  "Tier",
  "Type",
  "PKer",
  "PKee",
  "Assists",
];
const mobileColumns = ["Range", "Type", "PKer", "PKee"];

const dataColors = ["#c7eeff", "#c7eeff", "#bddaa5", "#e04b5a", ""];

const getTooltipData = (item, type) => {
  if (item && type) {
    const _data = type === "pkee" ? item.pkee : item.pker;
    const _clan = type === "pkee" ? item.killedClan : item.killerClan;
    return (
      <div className="d-flex flex-column align-items-start">
        <div>
          Tier: <span style={styles.tooltipValue}>{_data.tier}</span>
        </div>
        <div>
          Level: <span style={styles.tooltipValue}>{_data.level}</span>
        </div>
        <div>
          Race: <span style={styles.tooltipValue}>{_data.race}</span>
        </div>
        <div>
          Class: <span style={styles.tooltipValue}>{_data.class}</span>
        </div>
        <div>
          Clan:{" "}
          <span style={styles.tooltipValue}>
            {capitalizeFirstLetter(getShortClanNameFromLong(_clan))}
          </span>
        </div>
        <div>
          Streak:{" "}
          {_data.streak ? (
            <span style={styles.tooltipValue}>
              {_data.streak.type === "L" ? "Losing" : "Winning"}+
              {_data.streak.length}
            </span>
          ) : null}
        </div>
      </div>
    );
  }
};

const getData = (data = [], isMobile) => {
  let res = [];
  data
    .filter((e) => e.pkee && e.pker)
    .forEach((item) => {
      const _itemToPush = [
        !isMobile && (
          <span style={{ color: dataColors[0] }}>
            {getDateFormat(item.date, isMobile)}
          </span>
        ),
        !isMobile && (
          <span style={{ color: dataColors[4] }}>
            {item.pker.level} - {item.pkee.level}
          </span>
        ),
        !isMobile && (
          <span style={{ color: dataColors[4] }}>
            T{parseTier(item.pker.tier)} - T{parseTier(item.pkee.tier)}
          </span>
        ),
        isMobile && (
          <span style={{ color: dataColors[4] }}>
            {[200, 201].includes(item.pker.level) ? "SH" : "PRE-SH"}
          </span>
        ),
        <span style={{ color: dataColors[1] }}>{item.type.toUpperCase()}</span>,
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{getTooltipData(item, "pker")}</Tooltip>}
        >
          <span style={{ color: dataColors[2] }}>
            {item.killer}{" "}
            <div className="d-flex flex-row align-items-center">
              <Streak
                length={item.pker?.streak?.length || ""}
                type={item.pker?.streak?.type || ""}
              />
              <NavLink
                to={`/player?player=${item.killer}`}
                className="d-flex ms-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-box-arrow-up-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                  />
                  <path
                    fillRule="evenodd"
                    d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                  />
                </svg>
              </NavLink>
            </div>
          </span>
        </OverlayTrigger>,
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{getTooltipData(item, "pkee")}</Tooltip>}
        >
          <span style={{ color: dataColors[3] }}>
            {item.killed}

            <div className="d-flex flex-row align-items-center">
              <Streak
                length={item.pkee?.streak?.length || ""}
                type={item.pkee?.streak?.type || ""}
              />
              <NavLink
                to={`/player?player=${item.killed}`}
                className="d-flex ms-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-box-arrow-up-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                  />
                  <path
                    fillRule="evenodd"
                    d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                  />
                </svg>
              </NavLink>
            </div>
          </span>
        </OverlayTrigger>,
        !isMobile
          ? item.assists.length > 0
            ? item.assists.map((assist) => assist.assistKiller).join(", ")
            : []
          : "",
      ].filter((e) => e);
      res.push(_itemToPush);
    });
  return res;
};

function Last10Feed() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const isMobile = useIsMobile();

  useEffect(() => {
    const API_HOST = process.env.REACT_APP_API;
    // fetch data
    const dataFetch = async () => {
      const data = await (await fetch(`${API_HOST}public/get-last-10`)).json();
      if (data) {
        setTableData(getData(data, isMobile));
        setLoading(false);
      }
    };

    dataFetch();
  }, [isMobile]);
  return (
    <div style={styles.wrapper} className="row">
      {(loading && (
        <div className="spinner-border text-warning" role="status">
          <span className="sr-only" />
        </div>
      )) || (
        <Table2
          headers={isMobile ? mobileColumns : desktopColumns}
          data={tableData || []}
        />
      )}
    </div>
  );
}

export default Last10Feed;
