import { Table as BaseTable } from "react-bootstrap";

const styles = {
  header: { backgroundColor: "rgb(33, 37, 41)" },
};

function Table2(props) {
  const { headers, data } = props;
  return (
    <div>
      <BaseTable hover variant="dark" size="md" striped>
        <thead className="ps-3">
          <tr>
            {headers.map((header, index) => (
              <th className="ps-3" key={index} style={styles.header}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            return (
              <tr key={index + row[0]}>
                {row.map((rowItem, i) => {
                  return (
                    <td className="px-3" key={`${index}-${i}`}>
                      {rowItem}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BaseTable>
    </div>
  );
}

export default Table2;
