import { Col, Container, Row } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import NumberCard from "../components/numberCard/NumberCard";
import { useEffect, useState } from "react";
import HomeTitle from "../components/home/HomeTitle";
import { NavLink, useSearchParams } from "react-router-dom";
import Last10Feed from "../components/home/Last10Feed";
import { RecentActivity } from "../components/home/RecentActivity";
import { Epics } from "../components/epics/Epics";
import PlayerNumberCard from "../components/player/PlayerNumberCard";
import moment from "moment";

ChartJS.register(ArcElement, Tooltip, Legend);

const styles = {
  title: {
    color: "rgb(70, 130, 180)",
    marginBottom: "16px",
    marginTop: "32px",
  },
};

const blacklistedTitles = ['hourDouble', 'helpers', 'imms', 'shs', 'createdAt', 'count'];

const prettifiedTitles: Record<string, string> = {
  campaigns_done: "CPs Done",
  count: "Online",
  helpers: "Helpers",
  hourDouble: "Hour Double",
  imms: "IMMs",
  mob_deaths: "Mob Deaths",
  new_players: "New Players",
  new_remorts: "New Remorts",
  new_superheroes: "New SHs",
  notes_posted: "Notes Posted",
  number_of_logins: "Logins",
  quests_taken: "Quests Taken",
  triv_mobs_killed: "TP Mobs Killed",
};

function prettifyString(str: string): string {
  // Check if the prettified version exists in the prettifiedTitles map
  if (Object.prototype.hasOwnProperty.call(prettifiedTitles, str)) {
      return prettifiedTitles[str];
  } else {
      // If the prettified version doesn't exist, return the original string
      return str;
  }
}

type DashboardStats = {
  campaigns_done: number,
  count: number,
  createdAt: string,
  helpers: number,
  hourDouble: string,
  imms: number,
  mob_deaths: number,
  new_players: number,
  new_remorts: number,
  new_superheroes: number,
  notes_posted: number,
  number_of_logins: number,
  quests_taken: number,
  shs: number,
  triv_mobs_killed: number,
};

function Home() {
  const [dashboardStats, setDashboardStats] = useState<DashboardStats>();
  // Loading
  const [loading, setLoading] = useState(true);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const API_HOST = process.env.REACT_APP_API;

    // fetch data
    const dataFetch = async () => {
      const data = await (
        await fetch(`${API_HOST}public/stats/dashboard`)
      ).json();

      // set state when the data received
      setDashboardStats(data);
      setLoading(false);
    };

    dataFetch();

    // Re-fetch timer
    const timeToFetch = 5 * 60 * 1000;
    const interval = setInterval(() => {
      dataFetch();
    }, timeToFetch);

    return () => clearInterval(interval);
  }, [searchParams]);

  return (
    <>
      <Container>
        <Row>
          <HomeTitle hourDouble={dashboardStats?.hourDouble} mobDeaths={dashboardStats?.mob_deaths} />
        </Row>
      </Container>
      {/* Individual stats */}
      <div style={{ backgroundColor: "black" }}>
        <Container>
          <Row>
            <Col md={3} xs={6}>
              <NumberCard
                number={dashboardStats?.count || "NA"}
                text="online"
                loading={loading}
                color="rgb(146, 127, 191)"
              />
            </Col>
            <Col md={3} xs={6}>
              <NumberCard
                number={dashboardStats?.shs || 0}
                text="shs"
                loading={loading}
              />
            </Col>
            <Col md={3} xs={6}>
              <NumberCard
                number={dashboardStats?.imms || 0}
                text="imms"
                loading={loading}
              />
            </Col>
            <Col md={3} xs={6}>
              <NumberCard
                number={dashboardStats?.helpers || 0}
                text="helpers"
                loading={loading}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {/* Epics */}
      <Container>
        <div className="d-flex justify-content-between">
          <h3 style={styles.title}>EPICS</h3>
          <NavLink
            to="/epics"
            className="nav-link fs-5"
            style={{ marginTop: "32px" }}
          >
            EPICS CALENDAR{" "}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar-date-fill" viewBox="0 0 16 16" style={{marginTop: "-5px"}}>
              <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4zm5.402 9.746c.625 0 1.184-.484 1.184-1.18 0-.832-.527-1.23-1.16-1.23-.586 0-1.168.387-1.168 1.21 0 .817.543 1.2 1.144 1.2"/>
              <path d="M16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2m-6.664-1.21c-1.11 0-1.656-.767-1.703-1.407h.683c.043.37.387.82 1.051.82.844 0 1.301-.848 1.305-2.164h-.027c-.153.414-.637.79-1.383.79-.852 0-1.676-.61-1.676-1.77 0-1.137.871-1.809 1.797-1.809 1.172 0 1.953.734 1.953 2.668 0 1.805-.742 2.871-2 2.871zm-2.89-5.435v5.332H5.77V8.079h-.012c-.29.156-.883.52-1.258.777V8.16a13 13 0 0 1 1.313-.805h.632z"/>
            </svg>
          </NavLink>
        </div>
        <Epics />
      </Container>
      {/* Last 10 feed */}
      <Container>
        <h3 style={styles.title}>LATEST 10</h3>
        <Last10Feed />
      </Container>
      {/* Recent OPK/HC toggles and SetWanteds */}
      <Container>
        <Row>
          <h3 style={styles.title}>RECENT ACTIVITY</h3>
          <RecentActivity />
        </Row>
      </Container>
      {dashboardStats && 
      (<Container>
        <Row>
          <h3 style={styles.title}>LATEST STATS (TAKEN {moment(dashboardStats.createdAt).fromNow()?.toUpperCase()})</h3>
          {Object.entries(dashboardStats).map(([key, val], i) => {
            if (!blacklistedTitles.includes(key)) {
              return <Col md={4} sm={6} key={i}><PlayerNumberCard number={val?.toLocaleString() || "NA"} title={prettifyString(key)}/></Col>
            }
            return null;
          })}
        </Row>
      </Container>)}
      
    </>
  );
}

export default Home;
