import { Alert, ListGroup } from "react-bootstrap";

export const MostKilledList = (props) => {
  const { data = [] } = props;

  if (!data || data.length === 0) {
    return <Alert variant="primary">Not data found.</Alert>;
  }
  return (
    <ListGroup>
      {data.map((e, i) => {
        return (
          <ListGroup.Item
            key={i}
            style={{ backgroundColor: "#d86f9b", color: "#fff" }}
          >
            <div className="d-flex justify-content-between">
              <span>{e.key}</span>
              <span>{e.value}</span>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
};
