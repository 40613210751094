const styles: {
  title: React.CSSProperties;
} = {
  title: {
    color: "rgb(70, 130, 180)",
    marginBottom: "16px",
    marginTop: "32px",
    textTransform: "uppercase",
    paddingLeft: 0,
  },
};



export const Title = ({ text }: { text: string }) => {
  return <h3 style={styles.title}>{text}</h3>;
};
