const styles = {
  number: {
    fontSize: "42px",
    // color: "#4682b4",
    // color: "#aed9e0",
    letterSpacing: 0,
    fontWeight: 700,
  },
  text: {
    fontSize: "16px",
    // color: "#e4e5f2",
    color: "#4682b4",
    textTransform: "uppercase",
    fontWeight: 700,
  },
};

function NumberCard(props) {
  const { color, loading, number, text } = props;

  return (
    <div className="d-flex flex-column align-items-center mb-3 mt-2">
      {loading && (
        <div className="spinner-border text-warning" role="status">
          <span className="sr-only" />
        </div>
      )}
      {!loading && (
        <>
          <div style={{ ...styles.number, color: color || "#aed9e0" }}>
            {number}
          </div>
          <div style={styles.text}>{text}</div>
        </>
      )}
    </div>
  );
}

export default NumberCard;
