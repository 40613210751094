import { Card } from "react-bootstrap";

const PlayerNumberCard = (props) => {
  const { number, title } = props;
  return (
    <Card className="p-0 mb-3" style={{ backgroundColor: "#2a3d66" }}>
      <Card.Header
        style={{
          fontSize: "22px",
          color: "rgb(70, 130, 180)",
          textTransform: "uppercase",
        }}
        className="fw-bolder"
      >
        {title}
      </Card.Header>
      <Card.Body>
        <Card.Text
          style={{ color: "rgb(174, 217, 224)" }}
          className="fs-1 fw-bold"
        >
          {number}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default PlayerNumberCard;
