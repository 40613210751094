import { Col, Container, Row } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import moment from "moment-timezone";

import NumberCard from "../components/numberCard/NumberCard";
import Separator from "../components/Separator";
import React, { useEffect, useState } from "react";
import HomeTitle from "../components/home/HomeTitle";
import { useSearchParams } from "react-router-dom";
import LastWeekFeed from "../components/home/LastWeekFeed";
import LeaderboardCard from "../components/cards/LeaderboardCard";
import Top5KillsChart from "../components/charts/Top5KillsChart";

ChartJS.register(ArcElement, Tooltip, Legend);

const styles = {
  title: {
    color: "rgb(70, 130, 180)",
    marginBottom: "16px",
    marginTop: "32px",
  },
};

function LastWeek() {
  const [pkStatsWeekly, setPkStatsWeekly] = useState();
  const [loading, setLoading] = useState(true);
  const [titleDateRange, setTitleDateRange] = useState("");

  const [searchParams] = useSearchParams();

  useEffect(() => {
    setTitleDateRange(
      `${moment()
        .tz("America/New_York")
        .subtract(1, "weeks")
        .startOf("week")
        .format("ddd DD MMM")} - ${moment()
        .tz("America/New_York")
        .subtract(1, "weeks")
        .endOf("week")
        .format("ddd DD MMM")}`
    );
  }, [searchParams]);

  useEffect(() => {
    const API_HOST = process.env.REACT_APP_API;

    // fetch data
    const dataFetch = async () => {
      const data = await (
        await fetch(`${API_HOST}public/get-pk-week-stats?lastWeek=true`)
      ).json();

      // set state when the data received
      setPkStatsWeekly(data);

      setLoading(false);
    };

    dataFetch();

    // Re-fetch timer
    const timeToFetch = 15 * 60 * 1000;
    const interval = setInterval(() => {
      dataFetch();
    }, timeToFetch);

    return () => clearInterval(interval);
  }, [searchParams]);

  return (
    <Container className="Home">
      <Row>
        <HomeTitle subtitle={titleDateRange} />
      </Row>
      {/* Individual stats */}
      <Row
        style={{
          borderTop: "1px solid #6197ce",
          borderBottom: "1px solid #6197ce",
        }}
      >
        <Col md={3} xs={6}>
          <NumberCard
            number={pkStatsWeekly?.numberOfKills}
            text="total pks"
            loading={loading}
          />
        </Col>
        <Col md={3} xs={6}>
          <NumberCard
            number={pkStatsWeekly?.numberOfSoloKills || 0}
            text="solo pks"
            loading={loading}
          />
        </Col>
        <Col md={3} xs={6}>
          <NumberCard
            number={pkStatsWeekly?.leaderboards?.player[0]?.kda || "NA"}
            text="player kda"
            loading={loading}
          />
        </Col>
        <Col md={3} xs={6}>
          <NumberCard
            number={pkStatsWeekly?.leaderboards?.clan[0]?.kda || "NA"}
            text="clan kda"
            loading={loading}
          />
        </Col>
      </Row>
      {/* Top 5 kills for players and clans */}
      <Row>
        <h3 style={styles.title}>TOP 5 (PKS AND ASSISTS)</h3>
        <Col md={6}>
          <Top5KillsChart
            barColor="#3d2e4f"
            label="Players"
            leaderboardData={
              pkStatsWeekly?.leaderboards?.player.slice(0, 5) || []
            }
          />
        </Col>
        <Col md={6}>
          <Top5KillsChart
            barColor="#5d54a4"
            label="Clans"
            leaderboardData={
              pkStatsWeekly?.leaderboards?.clan.slice(0, 5) || []
            }
          />
        </Col>
      </Row>
      {/* Leaderboards */}
      <Row>
        <h3 style={styles.title}>LEADERBOARDS</h3>
        <Col lg={6} className="mb-4">
          {/* Players */}
          <LeaderboardCard
            title="Players"
            loading={loading}
            headers={["#", "Player", "KDA"]}
            text={
              pkStatsWeekly?.leaderboards.player?.map((row) => {
                return {
                  name: row.name,
                  value: `${row.pks} / ${row.deaths} / ${row.assists}`,
                  secondValue: row.kda,
                };
              }) || []
            }
          />
        </Col>
        <Col lg={6} className="mb-4">
          {/* Players */}
          <LeaderboardCard
            title="Clans"
            loading={loading}
            headers={["#", "Clan", "KDA"]}
            text={
              pkStatsWeekly?.leaderboards.clan?.map((row) => {
                return {
                  name: row.name,
                  value: `${row.pks} / ${row.deaths} / ${row.assists}`,
                  secondValue: row.kda,
                };
              }) || []
            }
          />
        </Col>
      </Row>
      <Separator />
      {/* All PKs */}
      <h3 style={styles.title}>PK FEED</h3>
      <LastWeekFeed />
    </Container>
  );
}

export default LastWeek;
