import moment from "moment";

const styles = {
  title: {
    color: "rgb(228, 229, 242)",
    fontSize: "40px",
    textTransform: "uppercase",
    letterSpacing: "10px",
  },
  subtitle: {
    color: "rgb(70, 130, 180)",
    textTransform: "uppercase",
  },
  highlight: {
    color: "#aed9e0",
    marginRight: "10px",
  },
  button: {
    borderColor: "black",
    color: "rgb(70, 130, 180)",
  },
  buttonSelected: {
    borderColor: "black",
    color: "rgb(174, 217, 224)",
  },
};

function HomeTitle(props) {
  const { hourDouble, mobDeaths } = props;

  const _mobsLeft = (1000000 - (mobDeaths % 1000000)).toLocaleString();

  return (
    <div className="d-flex flex-column align-items-start justify-content-center mt-4 mb-4">
      <h3
        style={styles.subtitle}
        className="d-flex justify-content-between w-100 m-0"
      >
        <span className="d-flex flex-column flex-md-row">
          <span className="me-3">Next hour double:</span>{" "}
          {hourDouble && (
            <>
              <span style={styles.highlight}>
                {moment(hourDouble).fromNow()}
              </span>
              <span>{_mobsLeft} left</span>
            </>
          )}
        </span>
      </h3>
    </div>
  );
}

export default HomeTitle;
