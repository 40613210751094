import { Col } from "react-bootstrap";

const styles = {
  entryTitle: {
    color: "rgb(70, 130, 180)",
    marginBottom: "16px",
    marginTop: "32px",
  },
  entryItem: {
    color: "rgb(174, 217, 224)",
  },
};

export const ChangelogItem = (props) => {
  const { title, changes } = props;
  return (
    <Col>
      <h5 style={styles.entryTitle}>{title}</h5>
      <ul>
        {changes.map((change, i) => (
          <li style={styles.entryItem} key={i}>
            {change}
          </li>
        ))}
      </ul>
    </Col>
  );
};
