import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Table2 from "../components/common/Table2";

const styles = {
  title: {
    color: "rgb(216, 111, 155)",
    marginBottom: "16px",
    marginTop: "32px",
  },
  name: {
    fontSize: "22px",
    color: "rgb(70, 130, 180)",
    textTransform: "uppercase",
  },
  value: {
    fontSize: "22px",
    color: "rgb(228, 229, 242)",
    textTransform: "uppercase",
    minWidth: "45",
    width: "90px",
  },
};

const desktopColumns = [
  "#",
  "Points",
  "Name",
  "PK",
  "HC",
  "Tier",
  "Class",
  "Clan",
];

const dataColors = [
  "#c7eeff",
  "#c7eeff",
  "#bddaa5",
  "#e04b5a",
  "#e04b5a",
  "#e04b5a",
  "#e04b5a",
  "",
];

const getData = (data = []) => {
  let res = [];
  data.forEach((item, index) => {
    const _itemToPush = [
      <span style={{ color: dataColors[0] }}>{index + 1}</span>,
      <span style={{ color: dataColors[1] }}>{item.points}</span>,
      <span style={{ color: dataColors[2] }}>{item.player}</span>,
      <span style={{ color: dataColors[3] }}>{item.pkKills}</span>,
      <span style={{ color: dataColors[4] }}>{item.hckills}</span>,
      <span style={{ color: dataColors[5] }}>{item.tier}</span>,
      <span style={{ color: dataColors[6] }}>{item.class}</span>,
      <span style={{ color: dataColors[7] }}>{item.clan}</span>,
    ].filter((e) => e);
    res.push(_itemToPush);
  });
  return res;
};

function LightBirthday() {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const API_HOST = process.env.REACT_APP_API;

    // fetch data
    const dataFetch = async () => {
      const data = await (
        await fetch(`${API_HOST}public/march-birthday-event`)
      ).json();

      // set state when the data received
      setData(data);
      setLoading(false);
    };

    dataFetch();

    // Re-fetch timer
    const timeToFetch = 5 * 60 * 1000;
    const interval = setInterval(() => {
      dataFetch();
    }, timeToFetch);

    return () => clearInterval(interval);
  }, []);
  return (
    <Container>
      <Row>
        <h3 style={styles.title}>Light Birthday PK Event - 03/17 to 03/22</h3>
        <Col
          md={6}
          className="mt-5 mt-md-0 d-flex flex-column justify-content-center align-items-end"
        >
          <div className="d-flex">
            <div style={styles.name}>PK Kill</div>
            <div style={styles.value} className="d-flex justify-content-end">
              5 pts
            </div>
          </div>
          <div className="d-flex">
            <div style={styles.name}>HC Kill</div>
            <div style={styles.value} className="d-flex justify-content-end">
              5 pts
            </div>
          </div>
          <div className="d-flex">
            <div style={styles.name}>As Oracle</div>
            <div style={styles.value} className="d-flex justify-content-end">
              7.5 pts
            </div>
          </div>
        </Col>
        <Col
          md={6}
          className="mt-5 mt-md-0 d-flex flex-column justify-content-center align-items-end"
        >
          <div className="d-flex">
            <div style={styles.name}>Tiers</div>
            <div style={styles.value} className="d-flex justify-content-end">
              {"<= 8"}
            </div>
          </div>
          <div className="d-flex">
            <div style={styles.name}>#1</div>
            <div style={styles.value} className="d-flex justify-content-end">
              10 tp
            </div>
          </div>
          <div className="d-flex">
            <div style={styles.name}>#2</div>
            <div style={styles.value} className="d-flex justify-content-end">
              5 tp
            </div>
          </div>
        </Col>
        <Col className="mt-3">
          {(isLoading && (
            <div className="spinner-border text-warning" role="status">
              <span className="sr-only" />
            </div>
          )) || <Table2 headers={desktopColumns} data={getData(data) || []} />}
        </Col>
      </Row>
    </Container>
  );
}

export default LightBirthday;
