import { Streak } from "../common/Streak";

const styles = {
  name: {
    fontSize: "22px",
    color: "rgb(70, 130, 180)",
    textTransform: "uppercase",
  },
  value: {
    fontSize: "22px",
    color: "rgb(228, 229, 242)",
    textTransform: "uppercase",
    minWidth: "45",
    width: "60px",
  },
};

const getValue = (value, type) => {
  if (!type) {
    return value;
  } else if (type === "streak") {
    return <Streak length={value.length} type={value.type} />;
  }
};

export const PlayerLineStat = ({ name, value, type }) => {
  return (
    <div className="d-flex">
      <div style={styles.name}>{name}</div>
      <div style={styles.value} className="d-flex justify-content-end">
        {getValue(value, type)}
      </div>
    </div>
  );
};
