import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Col, Row, Toast } from "react-bootstrap";
import { formatTime } from "../../utils/formatTime";
import { isEpicDone } from "../../utils/isEpicDone";

import "./styles.css";

export const Epics = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const API_HOST = process.env.REACT_APP_API;
    // fetch data
    const dataFetch = async () => {
      const res = await (await fetch(`${API_HOST}public/epics`)).json();
      if (res?.length > 0) {
        setData(res);
      }
      setLoading(false);
    };

    dataFetch();
  }, []);

  return (
    <Row>
      {(loading && (
        <div className="spinner-border text-warning" role="status">
          <span className="sr-only" />
        </div>
      )) ||
        (data?.length > 0 &&
          data.map((e) => {
            return (
              <Col xl={4} md={6} xs={12} key={e.noteId}>
                <Card
                  bg="dark"
                  text="white"
                  className={`mb-2 ${
                    isEpicDone(e.epics, e.startTimestamp)
                      ? "epic-card-muted"
                      : ""
                  }`}
                >
                  <Card.Header>
                    <div className="d-flex flex-column flex-lg-row">
                      <div>
                        {formatTime(e.startTimestamp)}
                        {e.customStartTimeString
                          ? ` ${e.customStartTimeString}`
                          : ""}
                      </div>
                      <div>
                        <span
                          className="ms-0 ms-lg-3 text-uppercase"
                          style={{ color: "rgb(174, 217, 224)" }}
                        >
                          {moment(e.startTimestamp).fromNow()}
                        </span>
                        {moment(e.startTimestamp).isSame(new Date(), "day") && (
                          <span className="text-danger ms-3">TODAY</span>
                        )}
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <Card.Title style={{ color: "orange" }}>
                      {e.epics}
                    </Card.Title>
                    <Card.Text style={{ color: "rgb(174, 217, 224, 0.5)" }}>
                      #<span className="epic-today-noteId">{e.noteId}</span> By:{" "}
                      <span className="epic-today-by">{e.poster}</span>
                    </Card.Text>
                    <Card.Text style={{ color: "rgba(174, 217, 224, 0.5)" }}>
                      Subj:{" "}
                      <span className="epic-today-subject">{e.subject}</span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            );
          }))}
      {!loading && data?.length === 0 && (
        <Col>
          <Toast show={true} animation={true}>
            <Toast.Body>No Epics Scheduled</Toast.Body>
          </Toast>
        </Col>
      )}
    </Row>
  );
};
