import { Col } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "left",
      labels: {
        color: "#bde0fe",
        font: {
          size: 18,
          color: "#666",
        },
      },
    },
  },
};

const getData = (data) => {
  return {
    labels: ["OPK", "HC"],
    datasets: [
      {
        label: "PK Types",
        data,
        backgroundColor: [
          "rgb(54, 162, 235)",
          "rgb(174, 217, 224)",
          "rgb(255, 205, 86)",
          "rgb(255, 99, 132)",
        ],
        hoverOffset: 4,
      },
    ],
  };
};

function PlayerPKSpreadChart(props) {
  const { data, isLoading } = props;

  return (
    <Col className="d-flex flex-column justify-content-between">
      {(isLoading && (
        <div className="spinner-border text-warning" role="status">
          <span className="sr-only" />
        </div>
      )) || (
        <>{data && <Doughnut data={getData(data)} options={chartOptions} />}</>
      )}
    </Col>
  );
}

export default PlayerPKSpreadChart;
