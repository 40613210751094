import { OverlayTrigger, Table as BaseTable, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";

const styles = {
  wrapper: {},
  columns: {
    backgroundColor: "transparent",
  },
  data: {
    backgroundColor: "transparent",
  },
  table: {
    borderColor: "#41729F",
  },
};

const getTdStyles = (data, filters, defaultColor) => {
  let filterMatched = false;
  if (filters && typeof data === "string") {
    filters.forEach((filter) => {
      if (filter && data.toLowerCase().search(filter.toLowerCase()) >= 0) {
        filterMatched = true;
      }
    });
  }
  return {
    ...styles.data,
    color: filterMatched ? "#f96d6d" : defaultColor,
  };
};

function Table(props) {
  const {
    columns,
    data,
    dataColors = [],
    filterStrings,
    links = [],
    tooltips = [],
  } = props;
  return (
    <div style={styles.wrapper}>
      <BaseTable hover variant="dark" size="sm" style={styles.table} responsive>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th style={styles.columns} key={index}>
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => {
            return (
              <tr key={index + row[0]}>
                {row.map((td, i) => {
                  if (Array.isArray(td)) {
                    return (
                      <td style={styles.data} key={index + "-" + i}>
                        {td.map((e, i) => {
                          return (
                            <span
                              key={i}
                              style={getTdStyles(
                                e,
                                filterStrings,
                                dataColors[i] || ""
                              )}
                            >{`${e}${i !== td.length - 1 ? ", " : ""}`}</span>
                          );
                        })}
                      </td>
                    );
                  }
                  return (
                    <td
                      style={getTdStyles(
                        td,
                        filterStrings,
                        dataColors[i] || ""
                      )}
                      key={i}
                    >
                      {tooltips[index] && tooltips[index][i] ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip>{tooltips[index][i]}</Tooltip>}
                        >
                          {links[index] && links[index][i] ? (
                            <Link
                              role="button"
                              to={links[index][i]}
                              style={getTdStyles(
                                td,
                                filterStrings,
                                dataColors[i] || ""
                              )}
                            >
                              {td}
                            </Link>
                          ) : (
                            <span>{td}</span>
                          )}
                        </OverlayTrigger>
                      ) : links[index] && links[index][i] ? (
                        <Link
                          role="button"
                          to={links[index][i]}
                          style={getTdStyles(
                            td,
                            filterStrings,
                            dataColors[i] || ""
                          )}
                        >
                          {td}
                        </Link>
                      ) : (
                        td
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BaseTable>
    </div>
  );
}

export default Table;
