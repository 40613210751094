import moment from "moment";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { getShortClanNameFromLong } from "../../utils/getShortClanNameFromLong";
import Table from "../common/Table";

const styles = {
  wrapper: {
    marginTop: "32px",
  },
};

const columns = [
  "#",
  "Date",
  "PKer",
  "PK Clan",
  "PKee",
  "PKee Clan",
  "Assists",
];

const dataColors = [
  "#ebd9dd",
  "#c7eeff",
  "#bddaa5",
  "#c7eeff",
  "#ebd9dd",
  "#c7eeff",
  "",
];

const searchFilter = (data, filter) => {
  if (filter) {
    return data.filter((row) => {
      const foundInKiller =
        row[2].toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      const foundInKillerClan =
        row[3].toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      const foundInKilled =
        row[4].toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      const foundInKilledClan =
        row[5].toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      const foundInAssists = row[6].some(
        (e) => e.toLowerCase().indexOf(filter.toLowerCase()) >= 0
      );
      return (
        foundInKiller ||
        foundInKilled ||
        foundInKillerClan ||
        foundInKilledClan ||
        foundInAssists
      );
    });
  } else {
    return data;
  }
};

const pkerFilter = (data, filter) => {
  if (filter) {
    return data.filter((row) => {
      const foundInKiller =
        row[2].toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      return foundInKiller;
    });
  } else {
    return data;
  }
};

function FeedTable(props) {
  const { search, week, pker } = props;

  const [tableData, setTableData] = useState([]);
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const API_HOST = process.env.REACT_APP_API;
    // fetch data
    const dataFetch = async () => {
      setLoading(false);
      const data = await (
        await fetch(
          `${API_HOST}public/get-weekly-pks${
            week === "last" ? "?lastWeek=true" : ""
          }`
        )
      ).json();
      if (data) {
        let res = [];
        let links = [];
        data.forEach((item, index) => {
          res.push([
            index + 1,
            moment(item.date, "YYYY-MM-DDTHH:mm:ss").format("MMM DD HH:mm:ss"),
            item.killer,
            capitalizeFirstLetter(getShortClanNameFromLong(item.killerClan)),
            item.killed,
            capitalizeFirstLetter(getShortClanNameFromLong(item.killedClan)),
            item.assists.map((assist) => assist.assistKiller),
          ]);
          links.push([
            "",
            "",
            `/player?player=${item.killer}`,
            "",
            `/player?player=${item.killed}`,
            "",
            "",
          ]);
        });
        setTableData(res);
        setLinks(links);
        setLoading(false);
      }
    };

    dataFetch();
  }, [week]);
  return (
    <div style={styles.wrapper} className="row">
      {(loading && (
        <div className="spinner-border text-warning" role="status">
          <span className="sr-only" />
        </div>
      )) || (
        <Table
          columns={columns}
          data={searchFilter(pkerFilter(tableData, pker), search) || []}
          filterStrings={[search, pker]}
          links={links}
          dataColors={dataColors}
        />
      )}
    </div>
  );
}

export default FeedTable;
