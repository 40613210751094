import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./styles.css";

function Header() {
  return (
    <Navbar bg="dark" expand="lg">
      <Container fluid>
        <Navbar.Brand className="brand-color">
          <NavLink to="/" className="brand-color">
            Activities for Aardwolf.com MUD
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" bg="light" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavLink to="/" className="nav-link ms-3">
              Dashboard
            </NavLink>
            <NavDropdown title="Stats" className="ms-3">
              <NavDropdown.Item as={NavLink} to="/leaderboards">
                Leaderboards
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/subclasses">
                Subclasses
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/races">
                Races
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/race-modifiers">
                Race Modifiers
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Lookup" className="ms-3">
              <NavDropdown.Item as={NavLink} to="/feed">
                PK Feed
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/player">
                Players
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/epics">
                Epics Calendar
              </NavDropdown.Item>
            </NavDropdown>
            <NavLink className="nav-link ms-3" to="/changelog">
              Changelog
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
