import { Col, Container, Row } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import { useEffect, useState } from "react";
import { Loading } from "../components/common/Loading";
import RacesChart from "../components/charts/RacesChart";
import { Title } from "../components/common/Title";

ChartJS.register(ArcElement, Tooltip, Legend);

function Races() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const API_HOST = process.env.REACT_APP_API;

    // fetch data
    const dataFetch = async () => {
      try {
        const data = await (
          await fetch(`${API_HOST}public/races?when=last30`)
        ).json();
        // set state when the data received
        setData(data);
      } catch (e) {
        console.log(e);
      }

      setLoading(false);
    };

    dataFetch();

    // Re-fetch timer
    const timeToFetch = 15 * 60 * 1000;
    const interval = setInterval(() => {
      dataFetch();
    }, timeToFetch);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <Title text={"Races (Last 30 days)"} />
      {/* Individual stats */}
      <Row className="mt-5">
        <Loading isLoading={loading}>
          <Col>
            <RacesChart data={data} />
          </Col>
        </Loading>
      </Row>
    </Container>
  );
}

export default Races;
