import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const styles = {
  wrapper: {
    backgroundColor: "#0075C4",
    marginBottom: "16px",
    marginRight: "16px",
    width: "100%",
    height: "100%",
  },
  rankNumber: {
    width: "25px",
    height: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
  },
  name: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 600,
    color: "rgb(199, 238, 255)",
  },
  value: {
    padding: "5px",
    textAlign: "right",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600,
    color: "rgb(199, 238, 255)",
  },
};

const backgroundColors = [
  "rgba(231, 111, 81, 0.8)",
  "rgba(244, 162, 97, 0.8)",
  "rgba(233, 196, 106, 0.8)",
  "rgba(42, 157, 143, 0.8)",
  "rgba(38, 70, 83, 0.8)",
];

const getRankStyles = (index) => {
  let additionalStyles = {};
  if ([0].includes(index)) {
    additionalStyles = {};
  }
  return {
    ...styles.rankNumber,
    backgroundColor: backgroundColors[index] || "#B744B8",
    ...additionalStyles,
  };
};

const getNameStyles = (index, title) => {
  let additionalStyles = {};
  if ([0].includes(index)) {
    additionalStyles = {
      fontWeight: 600,
    };
  }
  return {
    ...styles.name,
    ...additionalStyles,
    textDecoration: title === "Players" ? "underline" : "none",
  };
};

const getValueStyles = (index) => {
  let additionalStyles = {};
  if ([0].includes(index)) {
    additionalStyles = {
      fontWeight: 600,
    };
  }
  return {
    ...styles.value,
    ...additionalStyles,
  };
};

function LeaderboardCard(props) {
  const { loading, text, title, headers } = props;

  function getNameLink(name) {
    // if (title === "Clans") {
    //   let res = getShortClanNameFromLong(name);
    //   let firstChar = res.charAt(0).toUpperCase();
    //   res = firstChar + res.slice(1);
    //   return `/feed?search=${res}`;
    // }
    return `/feed?search=${name}`;
  }

  return (
    <Card style={styles.wrapper}>
      <Card.Body>
        <Card.Title style={{ color: "#a7cced" }} className="mb-4">
          {title}
        </Card.Title>
        {(loading && (
          <div className="spinner-border text-warning" role="status">
            <span className="sr-only" />
          </div>
        )) || (
          <Card.Text style={{ color: "#f2f8f6" }}>
            {/* Header */}
            <Row className="mb-3">
              <Col className="d-flex flex-row justify-content-between align-items-center">
                <div className="d-flex">
                  <span className="me-4 fw-bold" style={styles.rankNumber}>
                    {headers[0]}
                  </span>{" "}
                  <span className="fw-bold">{headers[1]}</span>
                </div>
                <div className="fw-bold">{headers[2]}</div>
              </Col>
            </Row>
            {/* Data */}
            {text.map((item, i) => {
              return (
                <Row
                  key={i}
                  className="py-2"
                  style={{
                    borderBottom: "1px solid rgb(97, 151, 206)",
                  }}
                >
                  <Col className="d-flex flex-row justify-content-between align-items-center">
                    <div className="d-flex">
                      <span style={getRankStyles(i)} className="me-4">
                        {i + 1}
                      </span>{" "}
                      {title === "Clans" ? (
                        <span style={getNameStyles(i)}>{item.name}</span>
                      ) : (
                        <Link to={getNameLink(item.name)}>
                          <span style={getNameStyles(i, title)}>
                            {item.name}
                          </span>
                        </Link>
                      )}
                    </div>
                    <div
                      style={getValueStyles(i)}
                      className="d-flex flex-column align-items-end"
                    >
                      <div>{item.value}</div>
                      <div
                        style={{ color: "rgb(255, 200, 221)", opacity: 0.7 }}
                      >
                        {item.secondValue}
                      </div>
                    </div>
                  </Col>
                </Row>
              );
            })}
          </Card.Text>
        )}
      </Card.Body>
    </Card>
  );
}

export default LeaderboardCard;
