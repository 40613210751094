export const Loading = ({ isLoading, children }) => {
  if (isLoading) {
    return (
      <div className="spinner-border text-warning" role="status">
        <span className="sr-only" />
      </div>
    );
  } else {
    return children;
  }
};
