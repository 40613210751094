const getStyles = (type) => {
  return {
    color: type === "L" ? "red" : "green",
  };
};

export const Streak = ({ length, type }) => {
  return (
    <div className="d-flex align-items-center" style={getStyles(type)}>
      {type === "L" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-down-left"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M2 13.5a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 0-1H3.707L13.854 2.854a.5.5 0 0 0-.708-.708L3 12.293V7.5a.5.5 0 0 0-1 0z"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-up-right"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z"
          />
        </svg>
      )}{" "}
      &nbsp;{" "}
      <span style={{ position: "relative", left: "-2px" }}>{length}</span>
    </div>
  );
};
