import moment from "moment/moment";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

import LeaderboardsTable from "../components/leaderboards/LeaderboardsTable";

const getSortOptions = (type) => {
  switch (type) {
    case "all":
      return (
        <>
          <option value="kda">KDA</option>
          <option value="totalKills">Total kills</option>
          <option value="pkKills">PK kills</option>
          <option value="hcKills">HC kills</option>
          <option value="totalDeaths">Total deaths</option>
          <option value="pkDeaths">PK deaths</option>
          <option value="hcDeaths">HC deaths</option>
          <option value="assists">Assists</option>
        </>
      );
    case "hc":
      return (
        <>
          <option value="kda">KDA</option>
          <option value="hcKills">HC kills</option>
          <option value="hcDeaths">HC deaths</option>
        </>
      );
    case "pk":
      return (
        <>
          <option value="kda">KDA</option>
          <option value="pkKills">PK kills</option>
          <option value="pkDeaths">PK deaths</option>
        </>
      );
    default:
      return (
        <>
          <option value="kda">KDA</option>
          <option value="totalKills">Total kills</option>
          <option value="pkKills">PK kills</option>
          <option value="hcKills">HC kills</option>
          <option value="totalDeaths">Total deaths</option>
          <option value="pkDeaths">PK deaths</option>
          <option value="hcDeaths">HC deaths</option>
          <option value="assists">Assists</option>
        </>
      );
  }
};

// All months until current (included)
const allMonthsUntilCurrent = moment.months().slice(0, moment().format("M"));

function Leaderboards() {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <Container>
      <Row className="mt-5">
        {/* Month */}
        <Col md={3} className="mb-3">
          <Form.Label style={{ color: "#6197ce", fontWeight: "bold" }}>
            Month
          </Form.Label>
          <Form.Select
            aria-label="Pick a month"
            onChange={(e) => {
              if (e.target.value !== moment().format("M")) {
                searchParams.set("week", "all");
              }
              searchParams.set("month", e.target.value);
              setSearchParams(searchParams);
            }}
            value={searchParams.get("month") || moment().format("M")}
          >
            <option value="all">All</option>
            {allMonthsUntilCurrent.map((month) => {
              return (
                <option
                  key={month}
                  value={`${moment().month(month).format("M")}`}
                >
                  {month}
                </option>
              );
            })}
          </Form.Select>
        </Col>
        {/* Week */}
        {!searchParams.get("month") ||
        searchParams.get("month") === moment().format("M") ? (
          <Col md={3} className="mb-3">
            <Form.Label style={{ color: "#6197ce", fontWeight: "bold" }}>
              Week
            </Form.Label>
            <Form.Select
              aria-label="Pick a week"
              onChange={(e) => {
                searchParams.set("week", e.target.value);
                setSearchParams(searchParams);
              }}
              value={searchParams.get("week") || "all"}
            >
              <option value="all">All</option>
              <option value="this">This week</option>
              <option value="last">Last week</option>
            </Form.Select>
          </Col>
        ) : null}
        {/* Search */}
        <Col md={3} className="mb-3">
          <Form.Label
            style={{ color: "#6197ce", fontWeight: "bold" }}
            className="text-bold"
          >
            Search
          </Form.Label>
          <Form.Control
            aria-label="Search"
            onChange={(e) => {
              searchParams.set("search", e.target.value);
              setSearchParams(searchParams);
            }}
            value={searchParams.get("search") || ""}
          />
        </Col>
      </Row>
      <Row className="mt-5">
        {/* Type */}
        <Col md={3} className="mb-3">
          <Form.Label style={{ color: "#6197ce", fontWeight: "bold" }}>
            Type
          </Form.Label>
          <Form.Select
            aria-label="Type"
            onChange={(e) => {
              searchParams.set("type", e.target.value);
              searchParams.set("sort", "kda");
              setSearchParams(searchParams);
            }}
            value={searchParams.get("type") || "all"}
          >
            <option value="all">All</option>
            <option value="hc">HC</option>
            <option value="pk">PK</option>
          </Form.Select>
        </Col>
        {/* Sort by */}
        <Col md={3} className="mb-3">
          <Form.Label style={{ color: "#6197ce", fontWeight: "bold" }}>
            Sort by
          </Form.Label>
          <Form.Select
            aria-label="Sort by"
            onChange={(e) => {
              searchParams.set("sort", e.target.value);
              setSearchParams(searchParams);
            }}
            value={searchParams.get("sort") || "kda"}
          >
            {getSortOptions(searchParams?.get("type") || "all")}
          </Form.Select>
        </Col>
      </Row>
      <Row>
        <Col>
          <LeaderboardsTable
            week={searchParams.get("week") || "all"}
            month={searchParams.get("month") || moment().format("M")}
            search={searchParams.get("search") || ""}
            sort={searchParams?.get("sort") || "kda"}
            type={searchParams?.get("type") || "all"}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Leaderboards;
